import ky from 'ky'


class HTTPError extends Error {}

export const axiosWeather = {

  async getWeather() {

    // Version 2.5
    //const data: any = await ky.get('https://api.openweathermap.org/data/2.5/weather?lat=35.51043168569523&lon=24.027344503472005&appid=8023e4182205203115c3cfe9fbcc1edf&units=metric&lang=el').json()
    
    // Version 3.0
    // https://openweathermap.org/api/one-call-3
    const data: any = await ky.get('https://api.openweathermap.org/data/3.0/onecall?lat=35.51043168569523&lon=24.027344503472005&exclude=hourly&appid=8023e4182205203115c3cfe9fbcc1edf&units=metric&lang=el').json()

    return data
  },

  async getHours() {

    //const data: any = await ky.get('https://worldtimeapi.org/api/timezone/Europe/Athens').json()
    const data: any = await ky.get('https://www.timeapi.io/api/time/current/zone?timeZone=Europe%2FAthens').json()
    
    if (!data) {
      throw new HTTPError(`Fetch error: ${data.statusText}`);
    }

    return data
  }



}
